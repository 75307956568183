var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("点呼代行")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(0),
            _c("div", { staticClass: "card-body" }, [
              _vm.loadingCount
                ? _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        "margin-top": "50px",
                        "margin-bottom": "50px"
                      }
                    },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.loadingCount
                ? _c("div", { staticStyle: { "margin-bottom": "50px" } }, [
                    _c(
                      "p",
                      { staticStyle: { "font-size": "1.3rem" } },
                      [
                        _vm._v("現在の状態:\n              "),
                        _vm.online
                          ? _c("span", { staticStyle: { color: "#388E3C" } }, [
                              _c("i", { staticClass: "fa fa-microphone" }),
                              _vm._v(" オンライン")
                            ])
                          : _vm._e(),
                        !_vm.online
                          ? _c("span", { staticStyle: { color: "#ef5350" } }, [
                              _c("i", {
                                staticClass: "fa fa-microphone-slash"
                              }),
                              _vm._v(" オフライン")
                            ])
                          : _vm._e(),
                        _c("a-button", { on: { click: _vm.changeOnline } }, [
                          _vm._v("状態を変更する")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "現在の点呼代行待ちの人数: " +
                            _vm._s(_vm.alcoholAgentCount) +
                            " 人 "
                        ),
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.refleshAgentCount(false)
                              }
                            }
                          },
                          [_vm._v("更新")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "p",
                      [
                        _vm.isAutoRefleshAgentCount
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "18px",
                                  color: "#388E3C"
                                }
                              },
                              [
                                _c("i", { staticClass: "icmn icmn-bullhorn" }),
                                _vm._v(" (現在 自動確認状態)")
                              ]
                            )
                          : _vm._e(),
                        _vm.isAutoRefleshAgentCount
                          ? _c(
                              "a-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setAutoRefleshAgentCount(false)
                                  }
                                }
                              },
                              [_vm._v("自動確認状態を解除する")]
                            )
                          : _c(
                              "a-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setAutoRefleshAgentCount(true)
                                  }
                                }
                              },
                              [_vm._v("自動確認状態にする")]
                            )
                      ],
                      1
                    ),
                    _vm.online
                      ? _c(
                          "p",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  shape: "round",
                                  size: "large",
                                  loading: _vm.loadingExecute
                                },
                                on: { click: _vm.executeRollCallBtn }
                              },
                              [_vm._v("点呼をする")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.linkedTarget
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              staticClass: "h2 text-center",
                              staticStyle: {
                                color: "#ef5350",
                                "margin-top": "20px"
                              }
                            },
                            [_vm._v("取得している点呼があります。")]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "a-form",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { layout: "inline" }
                  },
                  [
                    _vm._v("\n            対象日：\n            "),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.yesterday()
                          }
                        }
                      },
                      [_vm._v("前日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "3px" },
                        on: {
                          click: function($event) {
                            return _vm.today()
                          }
                        }
                      },
                      [_vm._v("今日")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "7px" },
                        on: {
                          click: function($event) {
                            return _vm.tomorrow()
                          }
                        }
                      },
                      [_vm._v("翌日")]
                    ),
                    _c(
                      "a-config-provider",
                      { attrs: { locale: _vm.locale } },
                      [
                        _c("a-date-picker", {
                          attrs: { placeholder: "対象日" },
                          model: {
                            value: _vm.targetDate,
                            callback: function($$v) {
                              _vm.targetDate = $$v
                            },
                            expression: "targetDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        staticStyle: {
                          "margin-left": "10px",
                          "margin-right": "20px"
                        },
                        attrs: { type: "primary" },
                        on: { click: _vm.refleshList }
                      },
                      [_vm._v("取得")]
                    )
                  ],
                  1
                ),
                _vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: {
                          "margin-top": "50px",
                          "margin-bottom": "50px"
                        }
                      },
                      [_c("a-spin", { attrs: { tip: "Loading..." } })],
                      1
                    )
                  : _vm._e(),
                !_vm.loading
                  ? _c(
                      "div",
                      [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.gotDate) + " に実行した点呼履歴")
                        ]),
                        _c("a-table", {
                          attrs: {
                            columns: _vm.columns,
                            "data-source": _vm.alcoholCheck,
                            pagination: false,
                            rowKey: "id",
                            rowClassName: function(r, i) {
                              return i % 2 === 0 && r.manual_flag === 1
                                ? "manual_row " + _vm.$style.stripe
                                : i % 2 === 0
                                ? _vm.$style.stripe
                                : r.manual_flag === 1
                                ? "manual_row"
                                : ""
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "driver",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(record.user_name) +
                                        " (" +
                                        _vm._s(record.vehicle_name) +
                                        ")\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "type",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.itemType[record.type]) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "item_allow_ride",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    record.type === 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.itemAllowRide[
                                                record.item_allow_ride
                                              ]
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    record.type === 1
                                      ? _c("span", [_vm._v("-")])
                                      : _vm._e()
                                  ])
                                }
                              },
                              {
                                key: "call_datetime",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("momentDateTime")(
                                            record.call_datetime
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "item_alcohol_detector",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.itemAlcoholDetector[
                                            record.item_alcohol_detector
                                          ]
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "item_drinking",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.itemDrinking[record.item_drinking]
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "item_roll_call_type",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.itemRollCallType[
                                            record.item_roll_call_type
                                          ]
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "roll_call_executer_id",
                                fn: function(id, record) {
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(record.executer_name) +
                                        "\n              "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "update",
                                fn: function(id, record) {
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            padding: "0 10px",
                                            "margin-right": "4px"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateAlcoholCheck(
                                                record.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            1806440079
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm.myCreateOperator
            ? _c("div", { staticClass: "card" }, [
                _vm._m(2),
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(
                      "対象月: " +
                        _vm._s(this.targetDate.format("YYYY年MM月")) +
                        "\n          "
                    )
                  ]),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            shape: "round",
                            size: "large",
                            loading: _vm.logsOnlineLoading
                          },
                          on: { click: _vm.exportCSVLogsOnline }
                        },
                        [_vm._v("取得をする")]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm.myCreateOperator
            ? _c("div", { staticClass: "card" }, [
                _vm._m(3),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "p",
                    { staticStyle: { "font-size": "1.3rem" } },
                    [
                      _vm._v("\n            対象期間: "),
                      _c("a-date-picker", {
                        attrs: { value: _vm.dateStart },
                        on: { change: _vm.changeDateStart }
                      }),
                      _vm._v(" 〜 "),
                      _c("a-date-picker", {
                        staticStyle: { "margin-right": "20px" },
                        attrs: { value: _vm.dateEnd },
                        on: { change: _vm.changeDateEnd }
                      }),
                      !_vm.dateRangeValid
                        ? _c("span", { staticStyle: { "font-size": "1rem" } }, [
                            _c("br"),
                            _vm._v("対象期間は100日以内に設定してください。")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _vm.dateRangeValid
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                shape: "round",
                                size: "large",
                                loading: _vm.logsCallLoading
                              },
                              on: { click: _vm.exportCSVLogsCall }
                            },
                            [_vm._v("取得をする")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: "点呼実行",
            visible: _vm.modalVisibleAgent,
            closable: false
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.targetAlcoholCheck.type === 0 ? "乗車前" : "乗車後"
                      )
                    )
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n          会社名: " +
                      _vm._s(_vm.targetAlcoholCheck.company_name) +
                      "( " +
                      _vm._s(_vm.targetAlcoholCheck.company_phonetic) +
                      " )"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          ユーザーID: " +
                      _vm._s(_vm.targetAlcoholCheck.user_id)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          運転者名: " +
                      _vm._s(_vm.targetAlcoholCheck.user_name) +
                      "( " +
                      _vm._s(_vm.targetAlcoholCheck.user_phonetic) +
                      " )"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          車両名: " +
                      _vm._s(_vm.targetAlcoholCheck.vehicle_name)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          点呼日時: " +
                      _vm._s(
                        _vm._f("momentDateTime")(
                          _vm.targetAlcoholCheck.call_datetime
                        )
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          運転者の電話番号: " +
                      _vm._s(_vm.targetAlcoholCheck.user_tel)
                  ),
                  _c("br")
                ]),
                _vm.targetAlcoholCheck.memo_for_operator
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "white-space": "pre-wrap",
                          "word-wrap": "break-word"
                        }
                      },
                      [
                        _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                          _vm._v("会社の運用メモ")
                        ]),
                        _c("pre", { staticStyle: { padding: "10px" } }, [
                          _vm._v(
                            _vm._s(_vm.targetAlcoholCheck.memo_for_operator)
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.absenceLogs.length > 0
                  ? _c("div", { staticClass: "mb-3" }, [
                      _c("p", { staticClass: "mb-0" }, [_vm._v("不在履歴")]),
                      _c(
                        "code",
                        {
                          staticStyle: {
                            padding: "10px",
                            border: "1px solid #e4e9f0",
                            width: "100%"
                          }
                        },
                        _vm._l(_vm.absenceLogs, function(log) {
                          return _c(
                            "span",
                            {
                              key: log.id,
                              staticStyle: { "line-height": "1.7" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("momentDateTime")(log.created_at)
                                ) +
                                  " (" +
                                  _vm._s(log.user_name) +
                                  ")"
                              ),
                              _c("br")
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c(
                  "p",
                  {
                    staticClass: "text-center",
                    staticStyle: { "margin-bottom": "45px" }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          shape: "round",
                          size: "large",
                          loading: _vm.checkMyCustomerLoading
                        },
                        on: { click: _vm.checkMyCustomer }
                      },
                      [_c("span", [_vm._v("電話をかける")])]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { display: "none" },
                        attrs: {
                          id: "calling",
                          href: _vm.targetAlcoholCheck.user_tel_tag
                        }
                      },
                      [_vm._v("tel")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("a-button", { on: { click: _vm.handleChangeAbsence } }, [
                      _vm._v("\n            不在にする\n          ")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-right mt10" },
                  [
                    _c(
                      "a-button",
                      {
                        class: _vm.$style.filledBtn,
                        staticStyle: {
                          "background-color": "#EF5350 !important"
                        },
                        on: { click: _vm.handleChangeCancelRequest }
                      },
                      [_vm._v("\n            キャンセルする\n          ")]
                    ),
                    _c("p", [
                      _vm._v("※キャンセルをするとこの点呼は削除されます。")
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "a-form-model",
                { attrs: { model: _vm.alcoholCheckForm } },
                [
                  _vm.targetAlcoholCheck.type === 0 &&
                  _vm.targetCheckYesterdayEnd
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "change_yesterday_end",
                          staticClass: "color-red",
                          attrs: {
                            label: "前日の乗車後点呼に変更",
                            prop: "change_yesterday_end"
                          }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value:
                                  _vm.alcoholCheckForm.change_yesterday_end,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.alcoholCheckForm,
                                    "change_yesterday_end",
                                    $$v
                                  )
                                },
                                expression:
                                  "alcoholCheckForm.change_yesterday_end"
                              }
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("変更しない")
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("変更する")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_allow_ride",
                      attrs: { label: "乗車可否", prop: "item_allow_ride" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_allow_ride,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_allow_ride",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_allow_ride"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("許可")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("非許可")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_alcohol_detector",
                      attrs: {
                        label: "アルコール検知器の有無",
                        prop: "item_alcohol_detector"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_alcohol_detector,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_alcohol_detector",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_alcohol_detector"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("有")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_drinking",
                      attrs: { label: "酒気帯びの有無", prop: "item_drinking" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_drinking,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_drinking",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_drinking"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("有")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_roll_call_type",
                      attrs: { label: "点呼方法", prop: "item_roll_call_type" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_roll_call_type,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_roll_call_type",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_roll_call_type"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("対面")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("ビデオ通話")
                          ]),
                          _c("a-radio", { attrs: { value: 3 } }, [
                            _vm._v("電話")
                          ]),
                          _c("a-radio", { attrs: { value: 4 } }, [
                            _vm._v("その他")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "alcohol_measurement_result",
                      attrs: {
                        label: "アルコール濃度",
                        prop: "alcohol_measurement_result"
                      }
                    },
                    [
                      _c("a-input", {
                        model: {
                          value:
                            _vm.alcoholCheckForm.alcohol_measurement_result,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.alcoholCheckForm,
                              "alcohol_measurement_result",
                              $$v
                            )
                          },
                          expression:
                            "alcoholCheckForm.alcohol_measurement_result"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.freeItemAlcoholCheck, function(item) {
                    return _c(
                      "div",
                      { key: item.id },
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: item.name } },
                          [
                            item.choice
                              ? _c(
                                  "div",
                                  _vm._l(item.choice.split("||"), function(
                                    choice
                                  ) {
                                    return _c(
                                      "a-button",
                                      {
                                        key: choice,
                                        on: {
                                          click: function($event) {
                                            return _vm.inputFreeItem(
                                              item.id,
                                              choice
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(choice))]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _c("a-input", {
                              model: {
                                value: _vm.freeItemAlcoholCheckContent[item.id],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.freeItemAlcoholCheckContent,
                                    item.id,
                                    $$v
                                  )
                                },
                                expression:
                                  "freeItemAlcoholCheckContent[item.id]"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _vm.updateDisableCheck
                ? _c("p", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(
                      "乗車可否・アルコール検知器の有無・酒気帯びの有無・点呼方法のいずれかが未選択になっているため更新できません。"
                    )
                  ])
                : _vm._e(),
              !_vm.isCalled
                ? _c("p", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(
                      "「電話をかける」がクリックされていないため更新できません。"
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "float-left" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.alcoholCheckConfirmLoading },
                      on: { click: _vm.handleCancelAlcoholCheckAgent }
                    },
                    [_vm._v("\n            やめる\n          ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      class: _vm.$style.filledBtn,
                      attrs: {
                        type: "primary",
                        loading: _vm.alcoholCheckConfirmLoading,
                        disabled: _vm.updateDisableCheck || !_vm.isCalled
                      },
                      on: { click: _vm.handleOkAlcoholCheckAgent }
                    },
                    [_vm._v("\n            更新\n          ")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "更新", visible: _vm.modalVisible },
          on: { cancel: _vm.handleCancelAlcoholCheckModal }
        },
        [
          _c(
            "div",
            [
              _c("p", [
                _vm._v(
                  "\n        会社名: " +
                    _vm._s(_vm.targetAlcoholCheck.company_name)
                ),
                _c("br"),
                _vm._v(
                  "\n        運転者名: " +
                    _vm._s(_vm.targetAlcoholCheck.user_name)
                ),
                _c("br"),
                _vm._v(
                  "\n        車両名: " +
                    _vm._s(_vm.targetAlcoholCheck.vehicle_name)
                ),
                _c("br"),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.targetAlcoholCheck.type === 0 ? "乗車前" : "乗車後"
                    )
                  )
                ]),
                _c("br"),
                _vm._v(
                  "\n        点呼日時: " +
                    _vm._s(
                      _vm._f("momentDateTime")(
                        _vm.targetAlcoholCheck.call_datetime
                      )
                    )
                ),
                _c("br"),
                _vm._v("\n        運転者の電話番号: "),
                _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "underline" },
                    attrs: { href: _vm.targetAlcoholCheck.user_tel_tag }
                  },
                  [_vm._v(_vm._s(_vm.targetAlcoholCheck.user_tel))]
                )
              ]),
              _c(
                "a-form-model",
                { attrs: { model: _vm.alcoholCheckForm } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_allow_ride",
                      attrs: { label: "乗車可否", prop: "item_allow_ride" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_allow_ride,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_allow_ride",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_allow_ride"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("許可")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("非許可")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_alcohol_detector",
                      attrs: {
                        label: "アルコール検知器の有無",
                        prop: "item_alcohol_detector"
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_alcohol_detector,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_alcohol_detector",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_alcohol_detector"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("有")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_drinking",
                      attrs: { label: "酒気帯びの有無", prop: "item_drinking" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_drinking,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_drinking",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_drinking"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("有")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [_vm._v("無")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "item_roll_call_type",
                      attrs: { label: "点呼方法", prop: "item_roll_call_type" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.alcoholCheckForm.item_roll_call_type,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.alcoholCheckForm,
                                "item_roll_call_type",
                                $$v
                              )
                            },
                            expression: "alcoholCheckForm.item_roll_call_type"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("未選択")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("対面")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("ビデオ通話")
                          ]),
                          _c("a-radio", { attrs: { value: 3 } }, [
                            _vm._v("電話")
                          ]),
                          _c("a-radio", { attrs: { value: 4 } }, [
                            _vm._v("その他")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.freeItemAlcoholCheck, function(item) {
                    return _c(
                      "div",
                      { key: item.id },
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: item.name } },
                          [
                            item.choice
                              ? _c(
                                  "div",
                                  _vm._l(item.choice.split("||"), function(
                                    choice
                                  ) {
                                    return _c(
                                      "a-button",
                                      {
                                        key: choice,
                                        on: {
                                          click: function($event) {
                                            return _vm.inputFreeItem(
                                              item.id,
                                              choice
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(choice))]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _c("a-input", {
                              model: {
                                value: _vm.freeItemAlcoholCheckContent[item.id],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.freeItemAlcoholCheckContent,
                                    item.id,
                                    $$v
                                  )
                                },
                                expression:
                                  "freeItemAlcoholCheckContent[item.id]"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: { click: _vm.handleCancelAlcoholCheckModal }
                    },
                    [_vm._v("\n            キャンセル\n          ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      class: _vm.$style.filledBtn,
                      attrs: {
                        type: "primary",
                        loading: _vm.alcoholCheckConfirmLoading
                      },
                      on: { click: _vm.handleOkAlcoholCheck }
                    },
                    [_vm._v("\n            更新\n          ")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("点呼取得")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("点呼履歴")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("オペレーターのオンライン日時")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("オペレーターの点呼履歴")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }