<template>
  <div>
    <h1 :class="$style.pageTitle">点呼代行</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h2>点呼取得</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingCount" class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingCount" style="margin-bottom: 50px;">
              <p style="font-size: 1.3rem">現在の状態:
                <span v-if="online" style="color: #388E3C;"><i class="fa fa-microphone"></i> オンライン</span>
                <span v-if="!online" style="color: #ef5350;"><i class="fa fa-microphone-slash"></i> オフライン</span>
                <a-button @click="changeOnline">状態を変更する</a-button>
              </p>
              <p>現在の点呼代行待ちの人数: {{ alcoholAgentCount }} 人 <a-button @click="refleshAgentCount(false)">更新</a-button></p>
              <p>
                <span v-if="isAutoRefleshAgentCount" style="font-size: 18px; color: #388E3C;"><i class="icmn icmn-bullhorn"></i> (現在 自動確認状態)</span><a-button v-if="isAutoRefleshAgentCount" @click="setAutoRefleshAgentCount(false)">自動確認状態を解除する</a-button>
                <a-button v-else @click="setAutoRefleshAgentCount(true)">自動確認状態にする</a-button>
              </p>
              <p class="text-center" v-if="online"><a-button shape="round" size="large" @click="executeRollCallBtn" :loading="loadingExecute">点呼をする</a-button></p>
              <div v-if="linkedTarget">
                <p class="h2 text-center" style="color: #ef5350; margin-top: 20px;">取得している点呼があります。</p>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h2>点呼履歴</h2>
          </div>
          <div class="card-body">
            <a-form layout="inline" style="margin-bottom: 20px;">
              対象日：
              <a-button @click="yesterday()" style="margin-right: 3px;">前日</a-button>
              <a-button @click="today()" style="margin-right: 3px;">今日</a-button>
              <a-button @click="tomorrow()" style="margin-right: 7px;">翌日</a-button>
              <a-config-provider :locale="locale">
                <a-date-picker placeholder="対象日" v-model="targetDate" />
              </a-config-provider>
              <a-button type="primary" @click="refleshList" style="margin-left: 10px; margin-right: 20px;" :class="$style.filledBtn">取得</a-button>
            </a-form>
            <div v-if="loading" class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <h3>{{ gotDate }} に実行した点呼履歴</h3>
              <a-table
                :columns="columns"
                :data-source="alcoholCheck"
                :pagination="false"
                rowKey="id"
                :rowClassName="
                  (r, i) => i % 2 === 0 && r.manual_flag === 1 ? 'manual_row ' + $style.stripe : i % 2 === 0 ? $style.stripe : r.manual_flag === 1 ? 'manual_row' : ''
                "
              >
                <span slot="driver" slot-scope="id, record">
                  {{ record.user_name }} ({{ record.vehicle_name }})
                </span>
                <span slot="type" slot-scope="id, record">
                  {{ itemType[record.type]  }}
                </span>
                <span slot="item_allow_ride" slot-scope="id, record">
                  <span v-if="record.type === 0">{{ itemAllowRide[record.item_allow_ride] }}</span>
                  <span v-if="record.type === 1">-</span>
                </span>
                <span slot="call_datetime" slot-scope="id, record">
                  {{ record.call_datetime | momentDateTime }}
                </span>
                <span slot="item_alcohol_detector" slot-scope="id, record">
                  {{ itemAlcoholDetector[record.item_alcohol_detector]  }}
                </span>
                <span slot="item_drinking" slot-scope="id, record">
                  {{ itemDrinking[record.item_drinking]  }}
                </span>
                <span slot="item_roll_call_type" slot-scope="id, record">
                  {{ itemRollCallType[record.item_roll_call_type]  }}
                </span>
                <span slot="roll_call_executer_id" slot-scope="id, record">
                  {{ record.executer_name }}
                </span>
                <span slot="update" slot-scope="id, record">
                  <a-button @click="updateAlcoholCheck(record.id)" style="padding: 0 10px; margin-right: 4px;">編集</a-button>
                </span>
              </a-table>
            </div>
          </div>
        </div>

        <div v-if="myCreateOperator" class="card">
          <div class="card-header">
            <h2>オペレーターのオンライン日時</h2>
          </div>
          <div class="card-body">
            <p style="font-size: 1.3rem">対象月: {{ this.targetDate.format('YYYY年MM月') }}
            </p>
            <p class="text-center"><a-button shape="round" size="large" @click="exportCSVLogsOnline" :loading="logsOnlineLoading">取得をする</a-button></p>
          </div>
        </div>

        <div v-if="myCreateOperator" class="card">
          <div class="card-header">
            <h2>オペレーターの点呼履歴</h2>
          </div>
          <div class="card-body">
            <p style="font-size: 1.3rem">
              対象期間: <a-date-picker :value="dateStart" @change="changeDateStart" /> 〜 <a-date-picker style="margin-right: 20px;" :value="dateEnd" @change="changeDateEnd" />
              <span v-if="!dateRangeValid" style="font-size: 1rem;"><br>対象期間は100日以内に設定してください。</span>
            </p>

            <p class="text-center"><a-button v-if="dateRangeValid" shape="round" size="large" @click="exportCSVLogsCall" :loading="logsCallLoading">取得をする</a-button></p>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="点呼実行"
      :visible="modalVisibleAgent"
      :closable="false"
    >
      <div>
        <div style="margin-bottom: 30px;">
          <p>
            <strong>{{ targetAlcoholCheck.type === 0 ? '乗車前' : '乗車後' }}</strong><br>
            会社名: {{ targetAlcoholCheck.company_name }}( {{ targetAlcoholCheck.company_phonetic }} )<br>
            ユーザーID: {{ targetAlcoholCheck.user_id }}<br>
            運転者名: {{ targetAlcoholCheck.user_name }}( {{ targetAlcoholCheck.user_phonetic }} )<br>
            車両名: {{ targetAlcoholCheck.vehicle_name }}<br>
            点呼日時: {{ targetAlcoholCheck.call_datetime | momentDateTime }}<br>
            運転者の電話番号: {{ targetAlcoholCheck.user_tel }}<br>
          </p>
          <div v-if="targetAlcoholCheck.memo_for_operator" style="white-space:pre-wrap; word-wrap:break-word;">
            <p style="margin-bottom: 0;">会社の運用メモ</p>
            <pre style="padding: 10px;">{{ targetAlcoholCheck.memo_for_operator }}</pre>
          </div>
          <div v-if="absenceLogs.length > 0" class="mb-3">
            <p class="mb-0">不在履歴</p>
            <code style="padding: 10px; border: 1px solid #e4e9f0; width: 100%;">
              <span v-for="log in absenceLogs" :key="log.id" style="line-height: 1.7;">{{log.created_at | momentDateTime }} ({{ log.user_name }})<br /></span>
            </code>
          </div>
          <p class="text-center" style="margin-bottom: 45px;">
            <a-button shape="round" size="large" @click="checkMyCustomer" :loading="checkMyCustomerLoading"><span>電話をかける</span></a-button>
            <a id="calling" :href="targetAlcoholCheck.user_tel_tag" style="display: none">tel</a>
          </p>
          <div class="text-right">
            <a-button @click="handleChangeAbsence">
              不在にする
            </a-button>
          </div>
          <div class="text-right mt10">
            <a-button @click="handleChangeCancelRequest" style="background-color: #EF5350 !important;" :class="$style.filledBtn">
              キャンセルする
            </a-button>
            <p>※キャンセルをするとこの点呼は削除されます。</p>
          </div>
        </div>
        <a-form-model
          :model="alcoholCheckForm"
        >
          <a-form-model-item v-if="targetAlcoholCheck.type === 0 && targetCheckYesterdayEnd" ref="change_yesterday_end" label="前日の乗車後点呼に変更" prop="change_yesterday_end" class="color-red">
            <a-radio-group v-model="alcoholCheckForm.change_yesterday_end">
              <a-radio :value="0">変更しない</a-radio>
              <a-radio :value="1">変更する</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_allow_ride" label="乗車可否" prop="item_allow_ride">
            <a-radio-group v-model="alcoholCheckForm.item_allow_ride">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">許可</a-radio>
              <a-radio :value="2">非許可</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_alcohol_detector" label="アルコール検知器の有無" prop="item_alcohol_detector">
            <a-radio-group v-model="alcoholCheckForm.item_alcohol_detector">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_drinking" label="酒気帯びの有無" prop="item_drinking">
            <a-radio-group v-model="alcoholCheckForm.item_drinking">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_roll_call_type" label="点呼方法" prop="item_roll_call_type">
            <a-radio-group v-model="alcoholCheckForm.item_roll_call_type">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">対面</a-radio>
              <a-radio :value="2">ビデオ通話</a-radio>
              <a-radio :value="3">電話</a-radio>
              <a-radio :value="4">その他</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="alcohol_measurement_result" label="アルコール濃度" prop="alcohol_measurement_result">
            <a-input v-model="alcoholCheckForm.alcohol_measurement_result" />
          </a-form-model-item>

          <div v-for="item in freeItemAlcoholCheck" :key="item.id">
            <a-form-model-item :label="item.name">
              <div v-if="item.choice">
                <a-button v-for="choice in item.choice.split('||')" :key="choice" @click="inputFreeItem(item.id, choice)">{{ choice }}</a-button>
              </div>
              <a-input v-model="freeItemAlcoholCheckContent[item.id]" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <p v-if="updateDisableCheck" style="text-align: left;">乗車可否・アルコール検知器の有無・酒気帯びの有無・点呼方法のいずれかが未選択になっているため更新できません。</p>
          <p v-if="!isCalled" style="text-align: left;">「電話をかける」がクリックされていないため更新できません。</p>
          <div class="float-left">
            <a-button :loading="alcoholCheckConfirmLoading" @click="handleCancelAlcoholCheckAgent">
              やめる
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="submit" type="primary" :loading="alcoholCheckConfirmLoading" @click="handleOkAlcoholCheckAgent" :class="$style.filledBtn" :disabled="updateDisableCheck || !isCalled">
              更新
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="更新"
      :visible="modalVisible"
      @cancel="handleCancelAlcoholCheckModal"
    >
      <div>
        <p>
          会社名: {{ targetAlcoholCheck.company_name }}<br>
          運転者名: {{ targetAlcoholCheck.user_name }}<br>
          車両名: {{ targetAlcoholCheck.vehicle_name }}<br>
          <span>{{ targetAlcoholCheck.type === 0 ? '乗車前' : '乗車後' }}</span><br>
          点呼日時: {{ targetAlcoholCheck.call_datetime | momentDateTime }}<br>
          運転者の電話番号: <a :href="targetAlcoholCheck.user_tel_tag" style="text-decoration: underline;">{{ targetAlcoholCheck.user_tel }}</a>
        </p>

        <a-form-model
          :model="alcoholCheckForm"
        >
          <a-form-model-item ref="item_allow_ride" label="乗車可否" prop="item_allow_ride">
            <a-radio-group v-model="alcoholCheckForm.item_allow_ride">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">許可</a-radio>
              <a-radio :value="2">非許可</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_alcohol_detector" label="アルコール検知器の有無" prop="item_alcohol_detector">
            <a-radio-group v-model="alcoholCheckForm.item_alcohol_detector">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_drinking" label="酒気帯びの有無" prop="item_drinking">
            <a-radio-group v-model="alcoholCheckForm.item_drinking">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">有</a-radio>
              <a-radio :value="2">無</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="item_roll_call_type" label="点呼方法" prop="item_roll_call_type">
            <a-radio-group v-model="alcoholCheckForm.item_roll_call_type">
              <a-radio :value="0">未選択</a-radio>
              <a-radio :value="1">対面</a-radio>
              <a-radio :value="2">ビデオ通話</a-radio>
              <a-radio :value="3">電話</a-radio>
              <a-radio :value="4">その他</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <div v-for="item in freeItemAlcoholCheck" :key="item.id">
            <a-form-model-item :label="item.name">
              <div v-if="item.choice">
                <a-button v-for="choice in item.choice.split('||')" :key="choice" @click="inputFreeItem(item.id, choice)">{{ choice }}</a-button>
              </div>
              <a-input v-model="freeItemAlcoholCheckContent[item.id]" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelAlcoholCheckModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="alcoholCheckConfirmLoading" @click="handleOkAlcoholCheck" :class="$style.filledBtn">
              更新
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
@import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

const notificationSound = new Audio('resources/sound/notification.mp3')

export default {
  data() {
    return {
      locale: jaJa,
      targetDate: moment(),
      gotDate: moment().format('YYYY/MM/DD'),
      dateStart: moment(),
      dateEnd: moment(),
      dateRangeValid: true,
      // loading: true,
      loading: false,
      loadingCount: false,
      loadingExecute: false,
      itemType: ['前', '後'],
      itemAllowRide: ['未選択', '許可', '非許可'],
      itemAlcoholDetector: ['未選択', '有', '無'],
      itemDrinking: ['未選択', '有', '無'],
      itemRollCallType: ['未選択', '対面', 'ビデオ通話', '電話', 'その他'],

      myRole: 3,
      myCreateOperator: 0,
      online: false,
      alcoholAgentCount: 0,
      isAutoRefleshAgentCount: false, // 点呼代行待ち人数の自動確認が有効かどうか
      autoRefleshTimerId: null, // 点呼代行待ち人数の自動確認のタイマーID
      isCalled: false,
      linkedTarget: false,

      columns: [
        {
          title: '点呼日時',
          dataIndex: 'call_datetime',
          scopedSlots: { customRender: 'call_datetime' },
          sorter: false,
        },
        {
          title: '会社名',
          dataIndex: 'company_name',
          scopedSlots: { customRender: 'company_name' },
          sorter: false,
        },
        {
          title: 'ユーザーID',
          dataIndex: 'user_id',
          scopedSlots: { customRender: 'user_id' },
          sorter: false,
        },
        {
          title: '運転手名（車両名）',
          dataIndex: 'driver',
          scopedSlots: { customRender: 'driver' },
          sorter: false,
        },
        {
          title: '乗車前後',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          sorter: false,
        },
        {
          title: '乗車可否',
          dataIndex: 'item_allow_ride',
          scopedSlots: { customRender: 'item_allow_ride' },
          sorter: false,
        },
        {
          title: 'アルコール検知器の有無',
          dataIndex: 'item_alcohol_detector',
          scopedSlots: { customRender: 'item_alcohol_detector' },
          sorter: false,
        },
        {
          title: '酒気帯びの有無',
          dataIndex: 'item_drinking',
          scopedSlots: { customRender: 'item_drinking' },
          sorter: false,
        },
        {
          title: '点呼方法',
          dataIndex: 'item_roll_call_type',
          scopedSlots: { customRender: 'item_roll_call_type' },
          sorter: false,
        },
        {
          title: '編集',
          dataIndex: '',
          scopedSlots: { customRender: 'update' },
          sorter: false,
        },
      ],
      alcoholCheck: [],

      freeItemAlcoholCheck: {},
      freeItemAlcoholCheckContent: {},

      modalVisibleAgent: false,

      modalVisible: false,
      alcoholCheckConfirmLoading: false,
      alcoholCheckForm: {
        item_alcohol_detector: 0,
        item_drinking: 0,
        item_roll_call_type: 0,
        item_allow_ride: 0,
        alcohol_measurement_result: 0,
        change_yesterday_end: 0,
      },
      targetAlcoholCheck: {},
      targetAlcoholCheckId: 0,
      targetCheckYesterdayEnd: false,

      logsOnlineLoading: false,
      logsCallLoading: false,

      checkMyCustomerLoading: false,
    }
  },
  computed: {
    // 不在履歴
    absenceLogs() {
      if (this.targetAlcoholCheck.absence_logs && this.targetAlcoholCheck.absence_logs.data) {
        return this.targetAlcoholCheck.absence_logs.data.concat()
          .sort((a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf())
      } else {
        return []
      }
    },
    updateDisableCheck() {
      return !(this.alcoholCheckForm.item_alcohol_detector * this.alcoholCheckForm.item_drinking * this.alcoholCheckForm.item_roll_call_type * this.alcoholCheckForm.item_allow_ride > 0)
    },
  },
  created() {
    const _this = this
    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(myResponse => {
      _this.myRole = myResponse.role
      _this.myCreateOperator = myResponse.create_operator_flag
      var online = moment('2000-01-01')
      if (myResponse.operator_online) online = moment(myResponse.operator_online)
      var diffStart = moment(moment().subtract(1, 'd').format('YYYY-MM-DD'))
      if (online.isSameOrAfter(diffStart)) _this.online = true
      else _this.online = false
      this.refleshList()
      this.refleshAgentCount()
    })
    this.getLinkedTarget()
  },
  filters: {
    momentDateTime: function (datetime) {
      if (datetime) return moment(datetime).format('YYYY/MM/DD HH:mm:ss')
      return ''
    },
    momentDate: function (date) { return moment(date).format('YYYY/MM/DD') },
    momentTime: function (time) { return moment(time, 'YYYY-MM-DD hh:mm:ss').format('HH:mm') },
  },
  methods: {
    yesterday() { this.targetDate = moment(this.targetDate).subtract(1, 'days') },
    today() { this.targetDate = moment() },
    tomorrow() { this.targetDate = moment(this.targetDate).add(1, 'days') },
    changeDateStart(date) {
      this.dateStart = date
      if (this.dateEnd.diff(this.dateStart, 'days') > 100) this.dateRangeValid = false
      else this.dateRangeValid = true
    },
    changeDateEnd(date) {
      this.dateEnd = date
      if (this.dateEnd.diff(this.dateStart, 'days') > 100) this.dateRangeValid = false
      else this.dateRangeValid = true
    },
    sortAlcoholCheck() {
      this.alcoholCheck.sort(function(a, b) {
        if (a.call_datetime > b.call_datetime) return -1
        if (a.call_datetime < b.call_datetime) return 1
        return 0
      })
    },
    getLinkedTarget() {
      const _this = this
      const linkedTarget = Vue.prototype.$api.send('get', 'alcohol/operator_linked_target')
      linkedTarget.then(response => {
        if (response) _this.linkedTarget = true
        else _this.linkedTarget = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': オペレーターに紐づいている点呼の取得に失敗しました。',
          })
          this.loadingCount = false
        })
    },
    refleshAgentCount(invokedByTimer) {
      if (this.loadingCount) {
        return
      }
      const _this = this
      this.loadingCount = true
      this.getLinkedTarget()
      const alcoholCountResult = Vue.prototype.$api.send('get', 'alcohol/agent/count')
      alcoholCountResult.then(response => {
        if (invokedByTimer && _this.alcoholAgentCount === 0 && response > 0) {
          // 自動確認で待機人数が0から1以上になったとき、音を鳴らす
          console.log('notificationSound.play()')
          notificationSound.play().catch((e) => { console.error(e) })
        }

        _this.alcoholAgentCount = response
        _this.loadingCount = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 現在の点呼代行待ちの人数の取得に失敗しました。',
          })
          this.loadingCount = false
        })
    },
    refleshList() {
      this.loading = true
      var bodyData = {
        date: this.targetDate.format('YYYY-MM-DD'),
      }
      const alcoholStartResult = Vue.prototype.$api.send('get', 'alcohol/call_execute', bodyData)
      alcoholStartResult.then(response => {
        this.alcoholCheck = response.map((item) => {
          item.free_item_alcohol_check_contents.data.map((fiacc) => {
            item['freeItemAlcoholCheck' + fiacc.free_item_alcohol_check_id] = fiacc.data
          })
          return item
        })
        this.sortAlcoholCheck()
        this.gotDate = this.targetDate.format('YYYY/MM/DD')
        this.loading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 点呼記録の取得に失敗しました。',
          })
          this.loading = false
        })
    },
    handleCancelAlcoholCheckModal() { this.modalVisible = false },
    updateAlcoholCheck(id) {
      const _this = this
      _this.targetAlcoholCheck = _this.alcoholCheck.find(e => e.id === id)
      _this.targetAlcoholCheck.user_tel_tag = 'tel:' + _this.targetAlcoholCheck.user_tel
      _this.targetAlcoholCheckId = id

      // 自由項目を取得する
      var bodyDataFreeItem = {
        company_id: _this.targetAlcoholCheck.company_id,
      }
      const freeItemAlcoholCheckResult = Vue.prototype.$api.send('get', 'free_item_alcohol_checks/company_has', bodyDataFreeItem)
      freeItemAlcoholCheckResult.then(response => {
        this.freeItemAlcoholCheck = response
        _this.alcoholCheckForm.item_alcohol_detector = _this.targetAlcoholCheck.item_alcohol_detector
        _this.alcoholCheckForm.item_drinking = _this.targetAlcoholCheck.item_drinking
        _this.alcoholCheckForm.item_roll_call_type = _this.targetAlcoholCheck.item_roll_call_type
        _this.alcoholCheckForm.item_allow_ride = _this.targetAlcoholCheck.item_allow_ride
        _this.alcoholCheckForm.alcohol_measurement_result = _this.targetAlcoholCheck.alcohol_measurement_result
        _this.freeItemAlcoholCheck.map((item) => {
          const targetFIACC = _this.targetAlcoholCheck['freeItemAlcoholCheck' + item.id]
          if (targetFIACC) _this.freeItemAlcoholCheckContent[item.id] = targetFIACC
          else _this.freeItemAlcoholCheckContent[item.id] = null
        })
        _this.modalVisible = true
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 自由項目の取得に失敗しました。',
          })
          this.freeItemAlcoholCheckLoading = false
        })
    },
    handleOkAlcoholCheck() { // 点呼履歴の更新
      const _this = this
      this.alcoholCheckConfirmLoading = true
      var bodyData = {
        id: this.targetAlcoholCheckId,
        item_alcohol_detector: this.alcoholCheckForm.item_alcohol_detector,
        item_drinking: this.alcoholCheckForm.item_drinking,
        item_roll_call_type: this.alcoholCheckForm.item_roll_call_type,
        item_allow_ride: this.alcoholCheckForm.item_allow_ride,
        alcohol_measurement_result: this.alcoholCheckForm.alcohol_measurement_result,
        free_item_alcohol_check_contents: {},
      }
      this.freeItemAlcoholCheck.map((item) => {
        bodyData.free_item_alcohol_check_contents[item.id] = this.freeItemAlcoholCheckContent[item.id]
      })

      const sendAlcoholCheck = Vue.prototype.$api.send('put', 'alcohol', bodyData)
      sendAlcoholCheck.then(response => {
        var targetUpdateAlcoholCheck = this.alcoholCheck.find(e => e.id === this.targetAlcoholCheckId)
        targetUpdateAlcoholCheck.call_datetime = response.call_datetime
        targetUpdateAlcoholCheck.confirmed = response.confirmed
        targetUpdateAlcoholCheck.item_alcohol_detector = this.alcoholCheckForm.item_alcohol_detector
        targetUpdateAlcoholCheck.item_drinking = this.alcoholCheckForm.item_drinking
        targetUpdateAlcoholCheck.item_roll_call_type = this.alcoholCheckForm.item_roll_call_type
        targetUpdateAlcoholCheck.item_allow_ride = this.alcoholCheckForm.item_allow_ride
        targetUpdateAlcoholCheck.alcohol_measurement_result = this.alcoholCheckForm.alcohol_measurement_result
        this.freeItemAlcoholCheck.map((item) => {
          targetUpdateAlcoholCheck['freeItemAlcoholCheck' + item.id] = this.freeItemAlcoholCheckContent[item.id]
        })
        _this.$notification['success']({
          message: '点呼記録簿を更新しました。',
        })
        this.sortAlcoholCheck()
        this.modalVisible = false
        this.alcoholCheckConfirmLoading = false
      })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholCheckConfirmLoading = false
          console.log(error)
        })
    },
    executeRollCallBtn() {
      const _this = this
      this.loadingExecute = true
      this.isCalled = false
      const alcoholCountResult = Vue.prototype.$api.send('get', 'alcohol/agent/first')
      alcoholCountResult.then(responseAC => {
        _this.targetAlcoholCheck = responseAC
        _this.targetAlcoholCheck.user_tel_tag = 'tel:' + responseAC.user_tel
        _this.targetAlcoholCheckId = responseAC.id
        _this.targetCheckYesterdayEnd = responseAC.check_yesterday

        // 自由項目を取得する
        var bodyDataFreeItem = {
          company_id: _this.targetAlcoholCheck.company_id,
        }
        const freeItemAlcoholCheckResult = Vue.prototype.$api.send('get', 'free_item_alcohol_checks/company_has', bodyDataFreeItem)
        freeItemAlcoholCheckResult.then(response => {
          _this.alcoholCheckForm.item_alcohol_detector = _this.targetAlcoholCheck.item_alcohol_detector
          _this.alcoholCheckForm.item_drinking = _this.targetAlcoholCheck.item_drinking
          _this.alcoholCheckForm.item_roll_call_type = _this.targetAlcoholCheck.item_roll_call_type
          _this.alcoholCheckForm.item_allow_ride = _this.targetAlcoholCheck.item_allow_ride
          _this.alcoholCheckForm.alcohol_measurement_result = _this.targetAlcoholCheck.alcohol_measurement_result
          _this.alcoholCheckForm.change_yesterday_end = 0
          _this.freeItemAlcoholCheck = response
          _this.freeItemAlcoholCheck.map((item) => {
            const targetFIACC = _this.targetAlcoholCheck.free_item_alcohol_check_contents.data.find(e => e.free_item_alcohol_check_id === item.id)
            if (targetFIACC) _this.freeItemAlcoholCheckContent[item.id] = targetFIACC.data
            else _this.freeItemAlcoholCheckContent[item.id] = null
          })
          _this.modalVisibleAgent = true
          this.refleshAgentCount(false)
          this.loadingExecute = false
        })
          .catch(error => {
            this.loadingExecute = false
            this.$notification['error']({
              message: error.status + ': 自由項目の取得に失敗しました。',
            })
            this.freeItemAlcoholCheckLoading = false
          })
      })
        .catch(error => {
          this.loadingExecute = false
          if (error.status === 400) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 点呼の実行対象の取得に失敗しました。',
            })
          }
          this.loading = false
        })
    },
    handleOkAlcoholCheckAgent() { // 点呼代行の更新
      const _this = this
      this.alcoholCheckConfirmLoading = true
      var bodyData = {
        id: this.targetAlcoholCheckId,
        item_alcohol_detector: this.alcoholCheckForm.item_alcohol_detector,
        item_drinking: this.alcoholCheckForm.item_drinking,
        item_roll_call_type: this.alcoholCheckForm.item_roll_call_type,
        item_allow_ride: this.alcoholCheckForm.item_allow_ride,
        alcohol_measurement_result: this.alcoholCheckForm.alcohol_measurement_result,
        change_yesterday_end: this.alcoholCheckForm.change_yesterday_end,
        free_item_alcohol_check_contents: {},
      }
      this.freeItemAlcoholCheck.map((item) => {
        bodyData.free_item_alcohol_check_contents[item.id] = this.freeItemAlcoholCheckContent[item.id]
      })

      const sendAlcoholCheck = Vue.prototype.$api.send('put', 'alcohol/agent/update', bodyData)
      sendAlcoholCheck.then(response => {
        _this.$notification['success']({
          message: '点呼記録簿を更新しました。',
        })
        this.modalVisibleAgent = false
        this.alcoholCheckConfirmLoading = false
        this.refleshAgentCount(false)
      })
        .catch(error => {
          if (error.status === 406 || error.status === 405 || error.status === 403 || error.status === 400) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            for (var key in error.data.errors) {
              _this.$refs[key].validateState = 'error'
              _this.$refs[key].validateMessage = error.data.errors[key]
            }
          }
          this.alcoholCheckConfirmLoading = false
          console.log(error)
        })
    },
    handleChangeAbsence() {
      const _this = this
      this.alcoholCheckConfirmLoading = true
      var bodyData = {
        id: _this.targetAlcoholCheckId,
      }
      const sendAlcoholCheck = Vue.prototype.$api.send('post', 'alcohol/agent/absence', bodyData)
      sendAlcoholCheck.then(response => {
        _this.targetAlcoholCheckId = 0
        _this.targetAlcoholCheck = {}
        this.modalVisibleAgent = false
        this.alcoholCheckConfirmLoading = false
        this.refleshAgentCount(false)
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.alcoholCheckConfirmLoading = false
          console.log(error)
        })
    },
    handleCancelAlcoholCheckAgent() {
      const _this = this
      this.alcoholCheckConfirmLoading = true
      var bodyData = {
        id: _this.targetAlcoholCheckId,
      }
      const sendAlcoholCheck = Vue.prototype.$api.send('post', 'alcohol/agent/cancel', bodyData)
      sendAlcoholCheck.then(response => {
        _this.targetAlcoholCheckId = 0
        _this.targetAlcoholCheck = {}
        this.modalVisibleAgent = false
        this.alcoholCheckConfirmLoading = false
        this.refleshAgentCount(false)
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.alcoholCheckConfirmLoading = false
          console.log(error)
        })
    },
    changeOnline() {
      const _this = this
      _this.loadingCount = true
      const result = Vue.prototype.$api.send('get', 'user/change_online')
      result.then(response => {
        _this.online = !_this.online
        _this.loadingCount = false
      })
    },
    exportCSVLogsOnline() {
      this.logsOnlineLoading = true
      var bodyData = {
        date: this.targetDate.format('YYYY-MM-DD'),
      }
      const logsResult = Vue.prototype.$api.send('get', 'operator/online_logs', bodyData)
      logsResult.then(response => {
        let csv = '\ufeff'
        csv += '名前,オンライン日時,オフライン日時,合計時間(秒)\n'
        response.forEach(res => {
          csv += res.name + ',' + res.start + ',' + res.end + ',' + res.total_time + '\n'
        })
        var blob = new Blob([csv], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'operator_online_logs.csv'
        link.click()
        this.logsOnlineLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ログの取得に失敗しました。',
          })
          this.logsOnlineLoading = false
        })
    },
    exportCSVLogsCall() {
      this.logsCallLoading = true
      const _this = this
      var bodyData = {
        date_start: this.dateStart.format('YYYY-MM-DD'),
        date_end: this.dateEnd.format('YYYY-MM-DD'),
      }
      const logsResult = Vue.prototype.$api.send('get', 'operator/call_logs', bodyData)
      logsResult.then(response => {
        let csv = '\ufeff'
        csv += '点呼日時,点呼執行者,会社名,運転手名,車両名,乗車前後,乗車可否,アルコール検知器の有無,酒気帯びの有無,点呼方法,点呼作成日時,最終点呼依頼日時\n'
        response.forEach(res => {
          csv += res.call_datetime + ',' + res.executer_name + ',' + res.company_name + ',' + res.user_name + ',' + res.vehicle_name + ',' + _this.itemType[res.type] + ',' + _this.itemAllowRide[res.item_allow_ride] + ',' + _this.itemAlcoholDetector[res.item_alcohol_detector] + ',' + _this.itemDrinking[res.item_drinking] + ',' + _this.itemRollCallType[res.item_roll_call_type] + ',' + res.created_at + ',' + res.roll_call_request_datetime + '\n'
        })
        var blob = new Blob([csv], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'operator_call_logs.csv'
        link.click()
        this.logsCallLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ログの取得に失敗しました。',
          })
          this.logsCallLoading = false
        })
    },
    // 点呼代行待ち人数の自動確認モードの切り替え
    setAutoRefleshAgentCount(mode) {
      if (mode === this.isAutoRefleshAgentCount) {
        return
      }
      this.isAutoRefleshAgentCount = mode

      if (mode) {
        // Safariの場合、非同期処理から音声再生ができないので、ボタン押下時に再生・即座に一時停止をしておく
        notificationSound.play().catch(() => {})
        notificationSound.pause()

        // 1分間隔で自動確認を行う (待機人数が0の場合のみ)
        this.autoRefleshTimerId = setInterval(() => {
          if (this.alcoholAgentCount === 0) {
            this.refleshAgentCount(true)
          }
        }, 60000)
      } else {
        clearInterval(this.autoRefleshTimerId)
        this.autoRefleshTimerId = null
      }
    },
    checkMyCustomer() {
      this.checkMyCustomerLoading = true
      this.isCalled = true
      const checkResult = Vue.prototype.$api.send('get', 'alcohol/operator_check/' + this.targetAlcoholCheckId)
      checkResult.then(response => {
        this.checkMyCustomerLoading = false
        if (response === 1) {
          document.getElementById('calling').click()
        } else {
          this.handleCancelAlcoholCheckAgent()
          alert('他のオペレーターの方と同じ点呼対象となってしまったので再度やりなおしてください。')
        }
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 電話前確認に失敗しました。',
          })
          this.checkMyCustomerLoading = false
        })
    },
    handleChangeCancelRequest() {
      var result = confirm('本当にキャンセルしますか？')
      if (result) {
        const _this = this
        this.alcoholCheckConfirmLoading = true
        var bodyData = {
          id: _this.targetAlcoholCheckId,
        }
        const sendAlcoholCheck = Vue.prototype.$api.send('post', 'alcohol/agent/cancel_request', bodyData)
        sendAlcoholCheck.then(response => {
          _this.targetAlcoholCheckId = 0
          _this.targetAlcoholCheck = {}
          this.modalVisibleAgent = false
          this.alcoholCheckConfirmLoading = false
          this.refleshAgentCount(false)
        })
          .catch(error => {
            _this.$notification['error']({
              message: error.data.data,
            })
            this.alcoholCheckConfirmLoading = false
            console.log(error)
          })
      }
    },
    inputFreeItem(id, text) {
      if (!this.freeItemAlcoholCheckContent[id]) this.freeItemAlcoholCheckContent[id] = text
      else this.freeItemAlcoholCheckContent[id] += text
      this.$forceUpdate()
    },
  },
}
</script>

<style>
.manual_row {
  background: #FCD6D9 0% 0% no-repeat padding-box;
}
.mt10 {
  margin-top: 10px;
}

.color-red label {
  color: #fb434a !important;
}
</style>
